<template>
  <component
    :is="print_type"
    :bill_receivable_group="bill_receivable_group"
    :company="company"
    :hasAddress="hasAddress"
    :hasPhones="hasPhones"
  />
</template>

<script>
import common_printing from "@/components/bill-receivable/sections/ReceiptCommonPrinting.vue";
import thermal_printing from "@/components/bill-receivable/sections/ReceiptThermalPrinting.vue";
export default {
  components: {
    common_printing,
    thermal_printing,
  },

  data() {
    return {
      bill_receivable_group: {
        bill_receivable_payment: [
          {
            bill_receivable: {},
          },
        ],
        customer: {},
      },
    };
  },

  async created() {
    this.$loading.start();

    await this.$http
      .show(
        "bill-receivable/bill-receivable-payment-group",
        this.$route.params.id
      )
      .then((response) => {
        this.bill_receivable_group = response.bill_receivable_group;
        this.$loading.finish();
      })
      .catch((error) => {
        this.$loading.finish();
      });
  },

  computed: {
    print_type() {
      return this.$store.getters["app/getTypePrint"];
    },
    company() {
      return this.$store.state.auth.userData.employee.company;
    },
    hasAddress() {
      return this.company ? this.company.main_address : false;
    },
    hasPhones() {
      return this.company ? this.company.phones[0] != null : false;
    },
  },
};
</script>

<style>
</style>